import type { ImageProps } from '@sparky/types';

import type { FocalPointImage } from '../types';

type ThumbSchema = {
  value: {
    src: string;
    height: string;
    width: string;
  };
};

/**
 * @deprecated This OldPattern is only here
 * for the duration of the migration from the old pattern to the new pattern.
 */
type OldPattern = {
  value: {
    alt: string;
    fields: {
      default?: ThumbSchema;
      extraLarge?: ThumbSchema;
      large?: ThumbSchema;
      medium?: ThumbSchema;
      small?: ThumbSchema;
      widescreen?: ThumbSchema;
    };
  };
  editable?: string;
};

export function mapImage(image: FocalPointImage | OldPattern | undefined) {
  // Fallback for old pattern, this will be cleaned up after the migration
  const editable = image?.editable;
  if (image?.value !== undefined && 'fields' in image.value) {
    const imageFields = image.value.fields;

    const srcSet = Object.values(imageFields)
      .map(format => `${format.value.src} ${format.value.width?.replace('px', 'w')}`)
      .join(', \n');

    const aspectRatio =
      imageFields?.small?.value?.width && imageFields?.small?.value?.height
        ? `${parseInt(imageFields.small.value.width)}/${parseInt(imageFields.small.value.height)}`
        : undefined;
    const sources = Object.entries(imageFields).reduce(
      (acc, [key, format]) => {
        switch (key) {
          case 'small':
            acc.sm = format.value.src;
            break;
          case 'medium':
            acc.md = format.value.src;
            break;
          case 'large':
            acc.lg = format.value.src;
            break;
          case 'extraLarge':
            acc.xl = format.value.src;
            break;
        }
        return acc;
      },
      {} as Exclude<ImageProps['sources'], undefined>,
    );

    return {
      alt: image.value.alt,
      src: imageFields.small?.value?.src ?? (image?.editable ? '' : undefined),
      sources,
      srcSet,
      aspectRatio,
      editable,
    };
  }

  const defaultImage =
    image?.value?.formats?.find(format => format.format === 'default') ??
    image?.value?.formats?.find(format => format.format === 'small');
  const src = defaultImage?.src || (image?.editable ? '' : undefined);
  const aspectRatio = defaultImage
    ? `${defaultImage.width?.replace('px', '')}/${defaultImage.height?.replace('px', '')}`
    : undefined;
  const srcSet = image?.value?.formats?.map(format => `${format.src} ${format.width?.replace('px', 'w')}`).join(', \n');
  const sources = image?.value?.formats?.reduce(
    (acc, format) => {
      switch (format.format) {
        case 'small':
          acc.sm = format.src;
          break;
        case 'medium':
          acc.md = format.src;
          break;
        case 'large':
          acc.lg = format.src;
          break;
        case 'extraLarge':
          acc.xl = format.src;
          break;
      }
      return acc;
    },
    {} as Exclude<ImageProps['sources'], undefined>,
  );
  const alt = image?.value?.alt || '';
  return { src, srcSet, alt, aspectRatio, sources, editable };
}
